import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getAccessToken } from '../../../utils/helpers';
import validate from './validate';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import { get } from '../../../utils/api/base';
import {
  getPropertyList,
  getRentByPropertyUnit, getTransaction,
  getTransactionCategory,
  getUnitList, paymentMethods
} from '../../../shared/helpers/getDatas';
import { generateFormInitialValue, getTotalCalculationData, prepareData, ucFirst } from '../../../shared/helpers';
import { transactionCreate, transactionUpdate } from '../../../utils/handleSubmit';
import { INVOICES, TRANSACTIONS } from '../../App/Router/api_routs';
import { UserRoles as UserRole } from '../../../shared/constants/userroles';
import renderDatePickerField from '@/shared/components/form/date-pickers/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { FormGroupDescription, FormGroupIcon } from '../../../shared/components/form/FormElements';
import Error from '../../../shared/components/form/Error';
import CreateInvoice from '../../Invoice/Components/add';

const CreateTransaction = () => {
  const token = getAccessToken();
  const { id } = useParams();
  const [initValue, setInitValue] = useState({});
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [property, setProperty] = useState('');
  const [tenant, setTenant] = useState('');
  const [amount, setAmount] = useState(0);
  const [gAmount, setGAmount] = useState(0);
  const [dueAmount, setDueAmount] = useState(null);
  const [advanceAmount, setAdvanceAmount] = useState(null);
  const [userId, setUserId] = useState('');
  const [invoiceOptions, setInvoiceOptions] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [invoiceId, setInvoiceId] = useState(0);
  const [showInvoiceDropdown, setShowInvoiceDropdown] = useState(false);
  const [paymentMethodData, setPaymentMethodData] = useState([]);
  const [errors, setErrors] = useState([]);
  const history = useHistory();

  const {
    rtl,
    theme,
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const show = (color, message) => useNotification({
    // eslint-disable-next-line react/no-unstable-nested-components
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up',
  });

  const handleTransactionTypeChange = async (selectedOption, e) => {
    const name = e.name;
    await getTransactionCategory(selectedOption, token, setCategories);
  };

  const handlePropertyChange = async (selectedOption, e) => {
    const name = e.name;
    setProperty(selectedOption.value);
    await getUnitList(token, selectedOption.value, 0, units, setUnits, false);
  };

  const handleUnitChange = async (selectedOption, e) => {
    const name = e.name;
    const unit_id = selectedOption.value;
    await getRentByPropertyUnit(token, property, unit_id, null, setUserId, setTenant);
    let urlParams = `?user_id=${userId}&unit=${unit_id}&limit=-1&status=Sent,Partially Paid`;
    const result = await get(INVOICES + urlParams, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    });
    let resultData = result?.data?.invoices?.data;
    if (resultData?.length > 0) {
      let invoiceOptions = [{
        value: '',
        label: 'Select Invoice'
      }];
      if(!id){
        resultData = [resultData.reduce(function(prev, current) {
          return (prev && prev.id > current.id) ? prev : current
        })];
      }
      for (const key in resultData) {
        let index = parseInt(key) + 1;
        invoiceOptions[index] = {
          value: resultData[key].id,
          label: resultData[key].invoice_number
        };
      }
      setInvoiceOptions(invoiceOptions);
      setInvoices(resultData);
    }
  };

  const calculateInvoiceAmount = async (invoice) => {
    let invoiceDetailAmounts = invoice.details.map((item) => parseFloat(item.amount));
    const invoiceTotalData = getTotalCalculationData(invoice.rent, invoiceDetailAmounts, invoice.adjustments, invoice.invoice_late_dis, invoice.old_due_amount);
    let gTotal = invoiceTotalData['gTotal'];
    //if (invoice.status === 'Partially Paid') {
    const oldTransaction = await getTransaction(token, invoice.id, -1, id);
    if (oldTransaction) {
      for (const oldTransactionKey in oldTransaction) {
        gTotal -= oldTransaction[oldTransactionKey].amount;
      }
    }
    //}
    return gTotal;
  };

  const handleInvoiceChange = async (selectedOption, e) => {
    const name = e.name;
    const selectedInvoice = invoices.find(function (item) {
      if (item.id === selectedOption.value) {
        return item;
      }
    });
    let gTotal = 0;
    if (selectedInvoice) {
      gTotal = await calculateInvoiceAmount(selectedInvoice);
    }
    setGAmount(gTotal);
    setAmount(gTotal);
    setInvoiceId(selectedOption.value);
    let err = errors;
    delete err['amount'];
    if (!gTotal) {
      err.amount = 'This field is required';
      setErrors(err);
    }
  };

  const handleAmountChange = async (e) => {
    const name = e.target.name;
    const currentAmount = e.target.value;
    if (invoiceId) {
      const da = parseFloat(gAmount) - parseFloat(currentAmount);
      setDueAmount(null);
      if (da > 0) {
        setDueAmount(da);
      }
      if ((da < 0 && !initValue.amount) || (initValue.amount && currentAmount > initValue.amount)) {
        let advanceAmount = Math.abs(da);
        if (initValue.amount && currentAmount > initValue.amount) {
          advanceAmount = advanceAmount - parseFloat(initValue.advance_amount);
        }
        setAdvanceAmount(advanceAmount);
      } else {
        setAdvanceAmount(0);
      }
    }
    setAmount(currentAmount);
    let err = errors;
    delete err['amount'];
    if (!e.target.value) {
      err.amount = 'This field is required';
      setErrors(err);
    }
  };

  const handleCategoryChange = (selectedOption, e) => {
    setShowInvoiceDropdown(false);
    if (selectedOption.value === 1) {
      setShowInvoiceDropdown(true);
    }
  };

  const handleTransactionCreateSubmit = async (values, form) => {
    let data = prepareData({ ...values }, ['amount'], []).data;
    data = { ...data };
    const paymentDate = data.payment_date ? new Date(data.payment_date) : new Date();
    if (!amount) {
      let err = errors;
      err.amount = 'This field is required';
      setErrors(err);
    }
    data.amount = amount;
    data.payment_date = paymentDate.format('yyyy-MM-dd');
    if (dueAmount > 0) {
      data.due_amount = dueAmount;
    }
    if (advanceAmount) {
      data.advance_amount = Math.abs(advanceAmount);
    }

    if (id) {
      await transactionUpdate(data, token)
        .then((result) => {
          if (result.data.status === 200) {
            show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    } else {
      await transactionCreate(data, token)
        .then((result) => {
          if (result.data.status === 201 || result.data.status === 200) {
            show('success', result.data.message);
            history.push('list');
            /*form.reset();
            for (let key in data) {
              form.resetFieldState(key);
            }*/
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    }
  };

  useEffect(async () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    await getPropertyList(token, source, setProperties);
    await paymentMethods(token, setPaymentMethodData);
    //setProperties()
    if (id) {
      await get(TRANSACTIONS + '/' + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      })
        .then(function (response) {
          if (response.data?.transaction) {
            let data = response.data?.transaction;
            getTransactionCategory({ value: data.transaction_type }, token, setCategories);
            getUnitList(token, data.property_id, 0, units, setUnits);
            const convertSelectDefault = {
              property: 'property_id',
              tenant: 'tenant_id',
              transaction_category: 'transaction_category_id',
              unit: 'unit_id',
              payment_methods: 'payment_method',
              invoice: 'invoice_id'
            };
            const mapValue = {
              property: 'name',
              tenant: 'name',
              transaction_category: 'name',
              unit: 'unit_name',
              payment_methods: 'name',
              invoice: 'invoice_number'
            };
            if (data.invoice) {
              calculateInvoiceAmount(data.invoice)
                .then(function (result) {
                  setGAmount(result);
                });
            }
            setProperty(data.property_id);
            setAmount(data.amount);
            setTenant(data.tenant.name);
            if(data.invoice){
              setInvoiceOptions([
                {
                  value: '',
                  label: 'Select Invoice'
                },
                {
                  value: data.invoice_id,
                  label: data.invoice.invoice_number
                }
              ]);
              setInvoices([data.invoice]);
            }
            data = generateFormInitialValue(data, convertSelectDefault, mapValue);
            data.transaction_type = {
              value: data.transaction_type,
              label: ucFirst(data.transaction_type)
            };
            setShowInvoiceDropdown(true);
            setInitValue(data);
          }
        });
    }

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Transaction</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Transaction Form</CardTitle>
                {/* <CardSubhead>Labels are above fields</CardSubhead> */}
              </CardTitleWrap>
              <Form onSubmit={(values, e) => handleTransactionCreateSubmit(values, e)}
                    initialValues={initValue}
                    validate={validate}>
                {({
                  handleSubmit,
                  form,
                  touched
                }) => {
                  return (
                    <FormContainer onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormGroupLabel>Transaction Type</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="transaction_type"
                            component={renderSelectField}
                            options={[{
                              value: 'income',
                              label: 'Income'
                            }, {
                              value: 'expense',
                              label: 'Expense'
                            }]}
                            onSelectChange={handleTransactionTypeChange}
                            placeholder="Select Transaction Type"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Transaction Category</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="transaction_category_id"
                            component={renderSelectField}
                            options={categories}
                            onSelectChange={handleCategoryChange}
                            placeholder="Select Transaction Category"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Property</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="property_id"
                            component={renderSelectField}
                            options={properties}
                            onSelectChange={handlePropertyChange}
                            placeholder="Select Property"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Unit</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="unit_id"
                            component={renderSelectField}
                            options={units[0]}
                            onSelectChange={handleUnitChange}
                            placeholder="Select Unit"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Tenant</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="tenant"
                            component={FormField}
                            type="number"
                            input={{
                              name: 'tenant',
                              value: tenant
                            }}
                            placeholder="Tenant"
                            readOnly="readonly"
                          />
                        </FormGroupField>
                      </FormGroup>
                      {showInvoiceDropdown &&
                        <FormGroup>
                          <FormGroupLabel>Invoice</FormGroupLabel>
                          <FormGroupField>
                            <Field
                              name="invoice_id"
                              component={renderSelectField}
                              options={invoiceOptions}
                              placeholder="Select Invoice"
                              onSelectChange={handleInvoiceChange}
                            />
                          </FormGroupField>
                        </FormGroup>
                      }
                      <Card id="lease-info">
                        <CardBody className="p-0">
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <FormGroupLabel>Payment Date</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="payment_date"
                                    component={renderDatePickerField}
                                    placeholder="Payment Date"
                                    autoComplete="off"
                                  />
                                  <FormGroupIcon>
                                    <CalendarBlankIcon/>
                                  </FormGroupIcon>
                                </FormGroupField>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <FormGroupLabel>Payment Method</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="payment_method"
                                    component={renderSelectField}
                                    options={paymentMethodData}
                                    placeholder="Select Payment Method"
                                  />
                                </FormGroupField>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <FormGroupLabel>Amount</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="amount"
                                    component={FormField}
                                    type="number"
                                    input={{
                                      name: 'amount',
                                      value: amount
                                    }}
                                    onChange={(e) => handleAmountChange(e)}
                                    placeholder="Amount"
                                  />
                                </FormGroupField>
                                {dueAmount && dueAmount > 0 &&
                                  <FormGroupDescription>
                                    Due Amount: <span style={{ 'color': 'red' }}>{dueAmount}</span>
                                  </FormGroupDescription>
                                }
                                {errors && errors.amount && (<Error error={errors.amount}/>)}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <FormGroupLabel>Paid By</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="paid_by"
                                    component={FormField}
                                    placeholder="Paid By"
                                  />
                                </FormGroupField>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <FormGroupLabel>Reference</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="reference"
                                    component={FormField}
                                    placeholder="Reference"
                                  />
                                </FormGroupField>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <FormGroupLabel>Remarks</FormGroupLabel>
                                <FormGroupField>
                                  <Field
                                    name="remarks"
                                    component="textarea"
                                    placeholder="Remarks"
                                  />
                                </FormGroupField>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Button variant="secondary" type="button" onClick={form.reset}>
                          Cancel
                        </Button>
                      </FormButtonToolbar>
                    </FormContainer>
                  );
                }}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default CreateTransaction;
